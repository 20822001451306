<template>
  <div class="full-height-wrapper">
    <div
        :class="{'has-mouse': hasMouse}"
        :style="{height: containerHeight + 'px'}"
        @touchstart="hasMouse = false"
        class="flipbook-wrapper full-screen">

      <span class="icon sc-icon icon-close"
            @click="closeFlipbook()">
      </span>

      <flipbook
          class="flipbook"
          :pages="pages"
          :startPage="pageNum"
          :style="{height: flipbookHeight + 'px'}"
          v-slot="flipbook"
          ref="flipbook"
          @flip-left-end="onFlipLeftEnd"
          @flip-right-end="onFlipRightEnd"
          @zoom-end="onZoomEnd">
      </flipbook>

      <div class="action-bar hide-on-print">
        <div class="wrapper-items-action-bar">

          <span :class="{ disabled: !canZoomIn }" class="icon sc-icon icon-zoom-plus" @click="onClick('zoomIn')"></span>
          <span :class="{ disabled: !canZoomOut }" class="icon sc-icon icon-zoom-minus" @click="onClick('zoomOut')"></span>
          <span v-if="fullScreen && supportsFullscreen" class="icon sc-icon icon-resize" @click="onClick('fitInView')"></span>
          <span v-if="!fullScreen && supportsFullscreen" class="icon sc-icon icon-fullscreen" @click="onClick('fullScreen')"></span>

          <span class="page-num icon sc-icon">
                    <input v-model.number="pageNum" @change="changePage" type='number' aria-label="page number" size="3"/> / {{ pageValues.numPages }}
                </span>

          <a v-if="allowDownload" :href="downloadLink" download class="icon sc-icon icon-download no-background-image"></a>

        </div>
      </div>

      <div v-show="canFlipLeft"  @click="onClick('flipLeft')" class="flipbook-button-prev icon sc-icon icon-arrow-left hide-on-print" slot="button-prev"></div>
      <div v-show="canFlipRight"  @click="onClick('flipRight')" class="flipbook-button-next icon sc-icon icon-arrow-right hide-on-print" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import flipbook from 'flipbook-vue';

export default {
  name: "FlipbookItem",
  props: ["flipbookData"],
  components: {
    flipbook
  },
  data: function() {
    return {
      hasMouse: true,
      pageNum: 1,
      numPages: null,
      previewImageSrc: "",
      fullScreen: false,
      flipbook: {},
      canZoomIn: true,
      canZoomOut: false,
      flipbookHeight: 0,
      containerHeight: 0,
      supportsFullscreen: false
    }
  },
  computed: {
    downloadLink(){
      return this.flipbookData.image['@link'];
    },
    images(){
      return this.flipbookData.images;
    },
    allowDownload(){
      return this.flipbookData.allowDownload;
    },
    pages: function() {
      let pages = [null]

      if(this.images) {
        let imgProps = [];
        for (let prop in this.images) {
          if (Object.prototype.hasOwnProperty.call(this.images, prop)) {

            if(prop.startsWith('page_'))
              imgProps.push(prop);
          }
        }

        for (let propIndex in imgProps){
          pages.push(process.env.VUE_APP_HOST + '/.imaging/original/dam/' + this.images['page_' + propIndex]);
        }
      }
      return pages;
    },
    pageValues() {
      let response = {}
      if(this.pages.length > 0) {
        let pageNum = 0
        if(this.pageNum != null) {
          pageNum = this.pageNum;
        }
        response = {page: pageNum, numPages: this.numPages}
      } else {
        response = {page: "", numPages: ""}
      }
      return response;
    },
    canFlipLeft(){
    return this.pageNum != 1;
    },
    canFlipRight(){
      return this.pageNum < this.pageValues.numPages;
    }
  },
  methods: {
    changePage(){
        if(this.pageNum > this.pageValues.numPages)
            this.pageNum = this.pageValues.numPages;
        else if(this.pageValues.page < 1)
            this.pageNum = 1

        let newUrl = `${location.href.replace(location.hash, '')}#${this.pageNum}`;
        history.replaceState(null, null, newUrl);
        this.setPageFromHash();
    },
    closeFlipbook() {
      history.replaceState(null, null,`${location.origin}${location.pathname}`);

      if(this.fullScreen)
        this.exitFullscreen();

      this.$emit('closeFlipbook');
    },
    onClick(element) {
      switch(element){
        case "flipLeft":
          this.flipbook.flipLeft()
          break;
        case "flipRight":
          this.flipbook.flipRight()
          break;
        case "zoomIn":
          this.flipbook.zoomIn()
          break;
        case "zoomOut":
          this.flipbook.zoomOut()
          break;
        case "fullScreen":
            this.fullScreen = true;
            this.activateFullscreen();
          break;
        case "fitInView":
            this.fullScreen = false;
            this.exitFullscreen();
          break;
        default:
          return false;
      }
    },
    activateFullscreen: function(){
      let domElement = document.querySelector('.flipbook-wrapper');

      // Cross-browser support
      if (domElement.requestFullscreen) {domElement.requestFullscreen();}
      else if (domElement.mozRequestFullScreen) {domElement.mozRequestFullScreen();}
      else if (domElement.webkitRequestFullscreen) {domElement.webkitRequestFullscreen();}
      else if (domElement.msRequestFullscreen) {domElement.msRequestFullscreen();}

    },
    exitFullscreen: function(){
      // Cross-browser support
      if (document.exitFullscreen) {document.exitFullscreen();}
      else if (document.webkitExitFullscreen) {document.webkitExitFullscreen();}
      else if (document.webkitCancelFullScreen) {document.webkitCancelFullScreen();}
      else if (document.mozCancelFullScreen) {document.mozCancelFullScreen();}
      else if (document.msExitFullscreen) {document.msExitFullscreen();}
    },
    onFlipLeftEnd: function(page) {
      this.pageNum = page;
      if(page != 1) {
        history.replaceState(null, null,`${location.origin}${location.pathname}#${page}`)
      } else {
        history.replaceState(null, null,`${location.origin}${location.pathname}`)
      }
    },
    onFlipRightEnd: function(page) {
      this.pageNum = page;
      if(page != 1) {
        history.replaceState(null, null,`${location.origin}${location.pathname}#${page}`)
      } else {
        history.replaceState(null, null,`${location.origin}${location.pathname}`)
      }
    },
    onZoomEnd: function() {
        setTimeout(()=>{
            this.canZoomIn = this.flipbook.canZoomIn;
            this.canZoomOut = this.flipbook.canZoomOut;
        }, 0);
    },
    // Adjust the elements sizes when the viewport changes and it's not detected by CSS
    // (e.g. mobile browsers toolbars appear)
    onResize: function(){
      this.flipbookHeight = window.innerHeight - 60 - 57 - 40;
      this.containerHeight = window.innerHeight;

      this.$nextTick(function () {
        this.$refs.flipbook?.onResize();
      });
    },
    setPageFromHash: function() {
      let n = parseInt(window.location.hash.slice(1), 10)
      if (Number.isInteger(n) & n !== undefined) {
        this.pageNum = n;
      }
    }
  },
  created() {
    this.setPageFromHash();
    this.onResize();
  },
  mounted() {
    this.flipbook = this.$refs.flipbook;

    if(this.flipbook) {
      this.numPages = this.flipbook.numPages
    }

    window.addEventListener("keydown", (ev) => {
      let flipbook = this.$refs.flipbook
      if(flipbook == undefined) return
      if (ev.keyCode == 37 & flipbook.canFlipLeft) flipbook.flipLeft()
      if (ev.keyCode == 39 & flipbook.canFlipRight) flipbook.flipRight()
    });

    window.addEventListener('hashchange', this.setPageFromHash);

    window.addEventListener('resize', this.onResize);

    // Only show fullscreen buttons when the functionality is available
    this.supportsFullscreen = document.querySelector('.flipbook-wrapper').requestFullscreen || document.querySelector('.flipbook-wrapper').webkitRequestFullscreen;

    if(this.supportsFullscreen){
      // Toggle the fullScreen property when an user exits fullscreen mode
      document.onfullscreenchange = () => {
        if(!document.fullscreenElement)
          this.fullScreen = false;
      }

      // Safari support (when will you stop doing this to me, Safari?)
      document.onwebkitfullscreenchange = () => {
        if(!document.webkitFullscreenElement)
          this.fullScreen = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/vars.scss';

.full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin:0 !important;
  z-index: 10000;
  height:100vh !important;

    .flipbook {
        height: calc(100vh - 60px - 30px - 57px); // viewport - wrapper-padding - actionbar-padding - actionbar-height
    }

    .icon-close {
        top:$spacer - rem(10px);
        right: $spacer;
        z-index: 1000;
        cursor: pointer;
        color: #000;
    }

    .flipbook-button-prev, .flipbook-button-next {
        top:calc(50vh - 21px);
    }
}

.action-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 30px;
}

.wrapper-items-action-bar {
  background-color: black;
  display: inline-block;
  padding: 0 10px;

    .icon.disabled{
        color:$gray-600;
        pointer-events: none;

      @media (hover: hover) and (pointer: fine) {
        &:hover{
          color:$gray-600 !important;
        }
      }
    }
}

.action-bar .sc-icon {
  font-size:rem(24px);
  height:calc(#{rem(30px)} + 30px);
  padding: 15px 20px;
  color: white;
  display: inline-block;
  cursor: pointer;
  line-height: 1;
}

.action-bar .sc-icon:before {
  position:relative;
  top:-5px;
}

@media (hover: hover) and (pointer: fine) {
  .action-bar .sc-icon:hover {
    color: #ccc;
  }
}

.action-bar .sc-icon.page-num {
  padding: 10px 30px;
  vertical-align: top;
  white-space: nowrap;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    input{
        width: rem(24px) * 2;
        background-color:$black;
        color:$white;
        border:none;
        border-bottom: 1px solid $white;
        border-radius:0;
        padding:0;
        line-height:0.5;
        text-align:right;
        font-weight:bold;
    }
}

.icon-close {
    position:absolute;
    top:$spacer + rem(97px);
    right: $spacer;
    z-index: 1000;
    cursor: pointer;
    color: #000;
}

.flipbook-wrapper {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: calc(100vh - 107px);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  color: #ccc;
  margin-bottom: $spacer;
  margin-top: -$spacer;
  margin-left: -$spacer;
  margin-right: -$spacer;
  padding-top:$spacer;
  padding-bottom: $spacer/2;
}

.flipbook {
  width: 90%;
  height: calc(100vh - 107px - 60px - 30px - 57px);// viewport - header-height - wrapper-padding - actionbar-padding - actionbar-height
  z-index:2;
}

.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 50px - 40px);
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

.flipbook-button-prev, .flipbook-button-next {
  display: block;
    position:absolute;
    top:calc(50vh + 37px - 21px);
    z-index:3;
    cursor:pointer;
}
.flipbook-button-prev:after, .flipbook-button-next:after {
  content: "";
}
.flipbook-button-prev {
  left: 30px;
  color: black;
}

.flipbook-button-next {
  right: 30px;
  color: black;
}

@include media-breakpoint-down(md){
  .full-height-wrapper{
    height:100vh;
    background-color:$custom-grey;
    margin-left: -$spacer/2;
    margin-right: -$spacer/2;
  }

  .icon-close{
      padding:15px;
      right:rem(5px);
      top:rem(-3px);
  }

  .full-screen .icon-close{
      top:rem(-3px);
      right:rem(5px)
  }

  .flipbook-wrapper {
    margin-top: -$spacer/2;
    margin-left: -$spacer/2;
    margin-right: -$spacer/2;
    padding-bottom:0 !important;
    height: calc(100vh - 67px);

    &.full-screen{
      padding-top: $spacer + rem(20px);
    }

    .flipbook {
        width: 90%;
        height: calc(100vh - 0px - 60px - 0px - 57px);// viewport - header-height - wrapper-padding - actionbar-padding - actionbar-height
    }

  }

  .action-bar {
    padding-top: 0;
    position:relative;
    bottom:-20px;
  }

  .action-bar .sc-icon {
    padding: 15px 10px;
  }

  .action-bar .sc-icon.page-num {
    padding: 10px 15px;
  }

  .wrapper-items-action-bar {
    padding: 0 5px;
  }
}
</style>